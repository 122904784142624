<template>
  <v-row wrap no-gutters class="pt-2">
    <v-col cols="12" class="pb-1">
      <h4>{{ $lang.status[type] }}</h4>
    </v-col>
    <v-col cols="12">
      <v-row wrap no-gutters>
        <v-col cols="10" class="pr-1">
          <v-autocomplete
            :key="`${type}-add`"
            v-model="selectedFromBE"
            multiple
            chips
            deletable-chips
            outlined
            dense
            :items="listFromBE"
            :loading="isLoading"
            :search-input.sync="searchFromBE"
            clearable
            hide-no-data
            item-text="name"
            item-value="id"
            :label="`${$lang.status[type]} ${$lang.labels.search}`"
            :placeholder="$lang.actions.startTyping"
            prepend-inner-icon="mdi-cloud-search-outline"
            :data-cy="`mass-change-search-${type}`"
          ></v-autocomplete>
        </v-col>
        <v-col cols="2" class="pl-1">
          <v-btn
            block
            color="accent"
            class="mr-1 color-accent-text"
            :data-cy="`mass-change-add-${type}`"
            @click="addFromBeToForMassChange()"
          >{{ $lang.actions.add }}</v-btn>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            :key="`${type}-select`"
            v-model="selectedForMassChange"
            outlined
            dense
            :items="listForMassChange"
            small-chips
            multiple
            hide-no-data
            hide-details
            deletable-chips
            hide-selected
            item-text="name"
            item-value="id"
            :label="`${$lang.status[type]} ${$lang.labels.toChange}`"
            :placeholder="$lang.actions.startTyping"
            prepend-inner-icon="mdi-cloud-search-outline"
            :data-cy="`mass-change-selected-group-${type}`"
          >
            <template v-slot:selection="data">
              <v-chip
                small
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                :data-cy="`mass-change-selected-${type}`"
                @click="data.select; openItem(data.item)"
                @click:close="remove(data.item)"
              >
                {{ data.item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="py-2">
      <hr>
    </v-col>
  </v-row>
</template>

<script>
import {
  getModuleByIdUsingGET as getModule, getModulesUsingGET as getModules,
  getProcessByIdUsingGET as getProcess,
  getProcessCredentialByIdUsingGET as getCredential,
  getProcessCredentialsUsingGET as getCredentials,
  getProcessesUsingGET as getProcesses,
  getSettingByIdUsingGET as getSetting, getSettingsUsingGET as getSettings,
  getTextTemplateByIdUsingGET as getTemplate,
  getTextTemplatesUsingGET as getTemplates, getTranslationsUsingGET as getTranslations,
  getTranslationUsingGET as getTranslation,
  getTriggerCronByIdUsingGET as getCron, getTriggerCronsUsingGET as getCrons,
  getTriggerRestByIdUsingGET as getRest, getTriggerRestsUsingGET as getRests,
  getValidationRuleByIdUsingGET as getValidationRule, getValidationRulesUsingGET as getValidationRules,
  getPluginByIdUsingGET as getPlugin, getPluginsUsingGET as getPlugins,
  getTriggerMessagingsUsingGET as getMessagings, getTriggerMessagingByIdUsingGET as getMessaging,
  getLibraryByIdUsingGET as getLibrary, getLibrariesUsingGET as getLibraries, getTriggerEventHandlerByIdUsingGET as getEventHandler,
  getTriggerEventHandlersUsingGET as getEventHandlers, getEntitiesUsingGET as getEntities, getEntityByIdUsingGET as getEntity
} from '@/utils/api'
import { mapActions } from 'vuex'

export default {
  props: {
    type: {
      type: String,
      default: () => ''
    },
    allResources: {
      type: Array,
      default: () => []
    },
    defaultValues: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isLoading: false,
      listFromBE: [],
      selectedFromBE: [],
      searchFromBE: '',
      listForMassChange: [],
      selectedForMassChange: [],
      resourcesTypes: {
        GLOBAL_TRANSLATION: 'templates/translations/edit',
        MODULE: 'modules/local/edit',
        PROCESS: 'processes/edit',
        PROCESS_CREDENTIAL: 'credentials/edit',
        SETTING: 'settings/edit',
        TEXT_TEMPLATE: 'templates/edit',
        TRIGGER_CRON: 'triggers/cron/edit',
        TRIGGER_REST: 'triggers/rest/edit',
        VALIDATION_RULE: 'validations/edit',
        PLUGIN: 'plugins/edit',
        TRIGGER_MESSAGING: 'triggers/messaging/edit',
        LIBRARY: 'libraries/edit',
        TRIGGER_EVENT_HANDLER: 'triggers/event-handler/edit',
        ENTITY: 'entities/edit'
      },
      showModal: false,
      dataForModal: []
    }
  },
  watch: {
    selectedForMassChange: {
      handler(val) {
        this.$emit('setValues', val ? val : null)
      },
      deep: true
    },
    searchFromBE: {
      handler(val) {
        if (val && val.length > 1) {
          switch (this.type) {
          case 'GLOBAL_TRANSLATION':
            this.searchTranslationsFunction(val)
            break
          case 'MODULE':
            this.searchModulesFunction(val)
            break
          case 'PROCESS':
            this.searchProcessesFunction(val)
            break
          case 'PROCESS_CREDENTIAL':
            this.searchCredentialsFunction(val)
            break
          case 'SETTING':
            this.searchSettingsFunction(val)
            break
          case 'TEXT_TEMPLATE':
            this.searchTextTemplatesFunction(val)
            break
          case 'TRIGGER_CRON':
            this.searchCronsFunction(val)
            break
          case 'TRIGGER_REST':
            this.searchRestsFunction(val)
            break
          case 'VALIDATION_RULE':
            this.searchValidationRulesFunction(val)
            break
          case 'PLUGIN':
            this.searchPluginsFunction(val)
            break
          case 'TRIGGER_MESSAGING':
            this.searchMessagingsFunction(val)
            break
          case 'LIBRARY':
            this.searchLibrariesFunction(val)
            break
          case 'TRIGGER_EVENT_HANDLER':
            this.searchEventHandlersFunction(val)
            break
          case 'ENTITY':
            this.searchEntitiesFunction(val)
            break
          default:
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),
    openItem(item) {
      const link = `/${localStorage.selectedLanguage || 'en'}/${this.resourcesTypes[this.type]}/${item.id}`

      window.open(link, '_blank')
    },
    remove (item) {
      const index = this.selectedForMassChange.map((x) => x.id).indexOf(item.id)

      if (index >= 0) this.selectedForMassChange.splice(index, 1)
      if (index >= 0) this.$emit('makeDirty', true)
    },
    async addFromBeToForMassChange() {
      if (!this.selectedFromBE.length) return

      this.selectedFromBE.forEach((item) => {
        const tempSelected = this.listFromBE.find((x) => x.id === item)

        if (this.selectedForMassChange.map((x) => x.id).includes(tempSelected.id)) return

        this.addDataToForMassChange(tempSelected)
      })

    },
    sendDataEmit(tempSelected) {
      if (tempSelected) this.listForMassChange.push(tempSelected)
      if (tempSelected) this.selectedForMassChange.push(tempSelected)
      this.$emit('makeDirty', true)
    },
    addDataToForMassChange(data) {
      this.listForMassChange.push(data)
      this.selectedForMassChange.push(data)
      this.selectedFromBE = []
    },
    async fetchModuleResource(resource) {
      return new Promise((resolve, reject) => {
        switch (resource.type) {
        case 'GLOBAL_TRANSLATION':
          getTranslation({ id: resource.id })
            .then((res) => {
              resolve({ type: resource.type, data: res.data.data })
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'MODULE':
          getModule({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'PROCESS':
          getProcess({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'PROCESS_CREDENTIAL':
          getCredential({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'SETTING':
          getSetting({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'TEXT_TEMPLATE':
          getTemplate({ templateId: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'TRIGGER_CRON':
          getCron({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'TRIGGER_REST':
          getRest({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'VALIDATION_RULE':
          this.fetchValidationRecursive(resource)
            .then((res) => {
              resolve(res)
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'PLUGIN':
          getPlugin({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'TRIGGER_MESSAGING':
          getMessaging({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'LIBRARY':
          getLibrary({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'TRIGGER_EVENT_HANDLER':
          getEventHandler({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'ENTITY':
          getEntity({ id: resource.id })
            .then((res) => {
              resolve({
                type: resource.type,
                data: res.data.data,
                isSelected: false,
                icon: this.iconHandler(resource.type)
              })
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        default:
        }
      })
    },
    iconHandler(type) {
      switch (type) {
      case 'PROCESS':
        return 'mdi-console-network'
      case 'TRIGGER_CRON':
        return 'mdi-briefcase-clock-outline'
      case 'TRIGGER_REST':
        return 'mdi-web'
      case 'PROCESS_CREDENTIAL':
        return 'mdi-lock'
      case 'TEXT_TEMPLATE':
        return 'mdi-text-box-outline'
      case 'GLOBAL_TRANSLATION':
        return 'mdi-text'
      case 'VALIDATION_RULE':
        return 'mdi-magnify-scan'
      case 'PLUGIN':
        return 'mdi-puzzle-outline'
      case 'MODULE':
        return 'mdi-briefcase-edit-outline'
      case 'SETTING':
        return 'mdi-cog'
      case 'TRIGGER_MESSAGING':
        return 'mdi-web'
      case 'LIBRARY':
        return 'mdi-library-outline'
      case 'STORAGE':
        return 'mdi-cube'
      case 'TRIGGER_EVENT_HANDLER':
        return 'mdi-briefcase-outline'
      case 'ENTITY':
        return 'mdi-database-settings-outline'
      default:
        return 'mdi-home'
      }
    },
    async fetchSettings(name) {
      return new Promise((resolve, reject) => {
        const obj = {
          name: name,
          page: 1,
          size: 1
        }

        getSettings(obj)
          .then((res) => {
            resolve(res.data.data.items[0])
          })
          .catch((error) => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false)
          })
      })
    },
    async fetchCredential(name) {
      return new Promise((resolve, reject) => {
        const obj = {
          name: name,
          page: 1,
          size: 1
        }

        getCredentials(obj)
          .then((res) => {
            resolve(res.data.data.items[0])
          })
          .catch((error) => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false)
          })
      })
    },
    async fetchProcess(name) {
      return new Promise((resolve, reject) => {
        const obj = {
          name: name,
          page: 1,
          size: 1
        }

        getProcesses(obj)
          .then((res) => {
            resolve(res.data.data.items[0])
          })
          .catch((error) => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false)
          })
      })
    },
    async fetchTextTemplate(name) {
      return new Promise((resolve, reject) => {
        const obj = {
          name: name,
          page: 1,
          size: 1
        }

        getTemplates(obj)
          .then((res) => {
            resolve(res.data.data.items[0])
          })
          .catch((error) => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false)
          })
      })
    },
    async fetchPlugin(name) {
      return new Promise((resolve, reject) => {
        const obj = {
          name: name,
          page: 1,
          size: 1
        }

        getPlugins(obj)
          .then((res) => {
            resolve(res.data.data.items[0])
          })
          .catch((error) => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false)
          })
      })
    },
    async fetchLibrary(name) {
      return new Promise((resolve, reject) => {
        const obj = {
          name: name,
          page: 1,
          size: 1
        }

        getLibraries(obj)
          .then((res) => {
            resolve(res.data.data.items[0])
          })
          .catch((error) => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false)
          })
      })
    },
    searchTranslationsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getTranslations(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listForMassChange.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchModulesFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getModules(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listForMassChange.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchProcessesFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getProcesses(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listForMassChange.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchCredentialsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getCredentials(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listForMassChange.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchSettingsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getSettings(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listForMassChange.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchTextTemplatesFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getTemplates(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listForMassChange.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchCronsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getCrons(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listForMassChange.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchRestsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getRests(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listForMassChange.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchValidationRulesFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getValidationRules(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listForMassChange.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchPluginsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getPlugins(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listForMassChange.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchMessagingsFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getMessagings(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listForMassChange.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchLibrariesFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getLibraries(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listForMassChange.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchEventHandlersFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.search = val

      getEventHandlers(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listForMassChange.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    searchEntitiesFunction(val = '') {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.search = val

      getEntities(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listForMassChange.some((entry) => entry.id === item.id)).map((x) => {
            return {
              id: x.id,
              name: x.name
            }
          })
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    }
  }
}
</script>
